const dataLayerPush = (data: unknown) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push(data);
  }
};

const TagManager = {
  dataLayerPush,
};

export default TagManager;
